.administration-header {
    font-size: 2em;
    color: var(--light-red);
    font-weight: 600;
    text-shadow: 1px 1px 5px black;
}

.administration-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}

.administration-person {
    width: 250px;
    height: 75px;
    text-align: left;
    flex-direction: row;
    display: flex;
    justify-self: center;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    border-radius: 10px;
}

.administration-person-leftbar {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.administration-person-rightbar {
    flex-direction: column;
    font-size: 16px;
    display: flex;
}

.administration-person-name {
    margin: 0;
    height: 50%;
    display: flex;
    align-items: flex-end;
}

.administration-person-rank {
    margin: 0;
    height: 50%;
}

.administration-person-avatar {
    margin: 0;
    box-shadow: 0px 0px 5px black;
}

/* Responsive */

@media only screen and (max-width: 1100px) {
    .administration-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .administration-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px 0px;
    }

    .administration-person-leftbar {
        width: 30%;
    }

    .administration-person {
        width: 190px;
        height: 75px;
        text-align: left;
        flex-direction: row;
        display: flex;
        justify-self: center;
    }
}

/* Ranks */

#Admin {
    color: rgb(255, 0, 100);
    text-shadow: 0px 0px 10px rgb(255, 0, 100);
}

#Moderator {
    color: rgb(0, 255, 200);
    text-shadow: 0px 0px 10px rgb(0, 255, 200);
}

#KidMod {
    color: lime;
    text-shadow: 0px 0px 10px lime;
}

#Helper {
    color: rgb(30, 140, 0);
    text-shadow: 0px 0px 10px rgb(30, 140, 0);
}