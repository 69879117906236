.navbar {
    background-color: initial;
    padding: 16px 64px;
    display: flex;
    background-color: var(--dark-red-transparent);
}

.navbar > a > img {
    height: inherit;
    width: 70px;
    height: 70px;
    vertical-align: middle;
}

.navbar-list {
    height: 64px;
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    font-size: 0.8em;
}

#navbar-link > li {
    padding: 0px 24px;
    user-select: none;
    text-shadow: 2px 2px 0px black;
}

#navbar-link {
    color: white;
    transition: 0.2s;
    font-weight: 600;
}

#navbar-link:hover {
    color: rgb(160, 170, 200);
}

.active {
    color: rgb(255, 0, 100) !important;
}

.navbar-button {
    margin-left: auto;
    align-items: center;
    cursor: pointer;
    display: none;
}

@media only screen and (max-width: 1150px) {

    .navbar {
        padding: 16px 32px;
    }

    .navbar-button {
        display: inline-flex;
    }

    .navbar-list {
        top: 102px;
        right: 0;
        z-index: 10;
        position: absolute;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
    
    #navbar-link {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        width: 100%;
        padding: 16px 0px;
        background-color: transparent;
    }
}