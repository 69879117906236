footer {
    margin-top: 32px;
    width: 100%;
    font-weight: 200;
    display: flex;
    background-color: var(--dark-red-transparent);
    font-size: 0.75em;
    text-align: left;
    padding: 16px 0px 16px 0px;
}

.footer-left {
    margin-right: auto;
    margin-left: 64px;
    display: flex;
    align-items: center;
}

.footer-right {
    margin-left: auto;
    margin-right: 64px;
    font-size: 2em;
    display: flex;
    align-items: center;
}

.footer-right svg {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
}

.footer-right > p > a:hover:nth-child(1) {
    color: rgb(80, 125, 255);
}

.footer-right > p > a:hover:nth-child(2) {
    color: rgb(200, 0, 0) !important;
}

.footer-right > p > a:hover:nth-child(3) {
    color: rgb(80, 80, 80);
}

@media only screen and (max-width: 600px) {

    footer {
        padding: 8px 0px 8px 0px;
    }
    
    .footer-left {
        margin-left: 32px;
        font-size: 0.8em;
    }
    
    .footer-right {
        margin-right: 32px;
        font-size: 1em !important;
    }

}