.admin {
    z-index: 10;
    width: 100%;
}

.admin > button {
    margin-left: auto;
    margin-right: auto;
}

.admin-chart {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.admin > table {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.85em;
    background-color: rgb(20, 20, 20);
    border-radius: 24px;
    padding: 4px;
}

.admin-income {
    background-color: rgb(15, 15, 15);
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    border-radius: 24px;
    border: 1px solid var(--light-red);
}