.payment-status-container {
    text-align: center;
    margin: 32px 0px 32px 0px;
}

.payment-status-header {
    color: var(--light-red);
    text-shadow: 1px 1px 5px black;
    font-size: 2em;
    font-weight: 600;
}

.payment-status-container-card {
    border-radius: 8px;
    background: white;
    color: rgb(73, 73, 73);
    width: 450px;
    height: 600px;
    font-weight: 600;
}

.payment-status-container-card-info {
    padding: 24px;
    font-weight: 400;
}

.payment-status-container-card-info > p {
    font-size: 2em;
    margin: 0;
    color: black;
    font-weight: 600;
}

.status-loader {
    padding-top: 45%;
}

/* Gorne czesci */

.payment-status-container-card-ok {
    padding: 24px;
    color: white;
    font-size: 3em;
    background: linear-gradient(to right, rgba(0, 255, 8, 0.8), rgba(16, 165, 45, 0.8), rgba(45, 165, 26, 0.8), rgba(0, 255, 60, 0.8));
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.payment-status-container-card-fail {
    padding: 24px;
    background: linear-gradient(to right, rgba(255, 0, 0, 0.8), rgba(138, 14, 14, 0.8), rgba(180, 32, 32, 0.8), rgba(255, 0, 0, 0.8));
    color: white;
    font-size: 3em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.payment-status-container-card-unknown {
    padding: 24px;
    background-color: rgb(67, 67, 67);
    color: white;
    font-size: 3em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* Responsywnosc */

@media only screen and (max-width: 500px) {

    .payment-status-container {
        margin: 0;
    }

    .payment-status-container-card {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .payment-status-container-card-info {
        padding: 16px;
        font-size: 0.85em;
    }
    
    .payment-status-container-card-info > p {
        font-size: 1.5em;
        margin-bottom: 12px;
    }

}