/* Opis */

.server {
    display: flex;
    margin-bottom: 128px;
    justify-content: space-between;
}

.server-description {
    width: 60%;
    padding: 64px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: auto;
    margin-bottom: auto;
}

.server-description-header {
    font-size: 2em;
    font-weight: 600;
    color: var(--light-red);
    margin: 0;
    margin-bottom: 24px;
}

.server-discord-btn {
    width: fit-content;
    min-width: 250px;
    padding: 16px;
    border-radius: 3px;
    background-color: rgb(0, 155, 255);
    color: white;
    box-shadow: 1px 1px 5px black;
    font-size: 0.65em;
    font-weight: 700;
}

.server-description-btns p:hover {
    filter: brightness(80%);
    transition: 0.2s;
    user-select: none;
}

.server-description-btns {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
}

.server-image {
    width: 500px;
    margin-left: 32px;
    margin-right: 128px;
    justify-content: center;
    align-items: center;
    justify-self: center;
    display: flex;
}

.server-image > img {
    filter: drop-shadow(0px 0px 15px black);
    width: 100%;
}

.server-description > a {
    color: var(--light-red);
}

.server-description > a:hover {
    color: var(--light-red-darker);
}

@media only screen and (max-width: 1350px) {

    .server-description {
        padding: 32px;
        font-size: 0.85em;
    }

    .server-image {
        width: 480px;
        margin-left: 32px;
        margin-right: 64px;
    }

}

@media only screen and (max-width: 1100px) {

    .server {
        display: flex;
        margin-bottom: 16px;
    }

    .server-description {
        width: 100%;
        align-items: center;
        padding: 0px;
    }

    .server-image {
        display: none;
    }

}

@media only screen and (max-width: 700px) {

    .server-description {
        font-size: 0.75em;
    }

    
    .server-description-btns {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

}