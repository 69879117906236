@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Titillium+Web:wght@200;300;400;700&display=swap');

:root {
  --light-red: rgb(250, 0, 80);
  --light-red-darker: rgb(180, 0, 55);
  --dark-red-transparent: rgba(5, 0, 0, 0.2);
}

body {
  margin: 0;
  padding: 0;
  font-family:'Titillium Web', sans-serif;
  color: white;
}

.app {
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  font-size: 1.5em;
  background-image: url('../assets/images/background2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

img {
  user-select: none;
}