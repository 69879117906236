/* Panele Danych */

.panes-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 96px;
}

.pane {
    display: flex;
    width: 400px;
    height: 100px;
    text-align: left;
    flex-direction: row;
    justify-self: center;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    border-radius: 10px;
    padding: 24px;
    font-family: Titillium Web,sans-serif;
    text-shadow: 3px 3px 5px black;
}

/* LEWA STRONA */

.pane-leftside {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pane-left-top {
    margin: 0;
    height: 50%;
    display: flex;
    align-items: flex-end;
    font-size: 36px;
    font-weight: bold;
}

.pane-left-bottom {
    margin: 0;
    height: 50%;
    font-size: 20px;
    font-weight: 200;
}

/* PRAWA STRONA */

.pane-rightside {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.pane-rightside > svg {
    width: 100px;
    height: 60%;
    color: var(--light-red);
}

/* Media */

@media only screen and (max-width: 1500px) {

    .panes-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px;
    }

    .pane:nth-child(3) {
        grid-column: span 2;
    }

}

@media only screen and (max-width: 1100px) {

    .panes-container {
        grid-template-columns: 1fr;
        grid-gap: 32px;
    }

    .pane:nth-child(3) {
        grid-column: span 1;
    }

}

@media only screen and (max-width: 500px) {

    .pane {
        width: 80%;
        height: 100px;
    }

}