/* Browser */

.shop {
    display: flex;
    width: 100%;
    justify-content: center;
}

.shop-redirect {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    text-shadow: 0px 0px 30px var(--light-red);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 10;
}

.shop-browser {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 1500px;
}

.shop-browser-header {
    font-size: 2em;
    font-weight: 600;
    color: var(--light-red);
}

.shop-browser-header-info {
    font-size: 1.4em;
    font-weight: 600;
    color: var(--light-red-darker);
    text-decoration: underline;
}

.shop-browser-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
}

/* Purchases */

.shop-browser-purchases-header {
    font-size: 1.5em;
    font-weight: 600;
    color: white;
    text-align: center;
}

.shop-browser-recent-purchases {
    display: flex;
    background-color: rgba(255, 255, 255, 0.075);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    box-shadow: 0px 0px 16px rgb(20, 20, 20);
    border-radius: 8px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
}

.shop-browser-purchase {
    margin: 24px 16px;
    font-weight: 200;
    font-size: 0.75em;
    display: flex;
}

.shop-browser-purchase > img {
    border-radius: 10px;
    border: 3px solid black;
}

.shop-browser-purchase > img:hover {
    border: 3px solid var(--light-red);
}

/* Item */

.shop-browser-item {
    background-color: rgba(255, 255, 255, 0.075);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 32px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 16px rgb(20, 20, 20);
}

/* Left Side Of item */

.shop-browser-item-left-side {
    display: flex;
    width: 192px;
    height: 100%;
    align-items: center;
}

.shop-browser-item-left-side > img {
    width: 192px;
    height: 192px;
}

.coin-shadow {
    filter: drop-shadow(0px 0px 5px rgb(208, 180, 70));
}

/* Right Side Of Item */

.shop-browser-item-right-side {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.shop-browser-item-header {
    margin: 0px 0px 0px 0px;
    font-size: 1.25em;
    font-weight: 600;
    color: var(--light-red);
}

.shop-browser-item-description {
    margin: 0px 0px 0px 0px;
    font-size: 0.75em;
    font-weight: 200;
    color: white;
    flex: 1;
}

.shop-browser-item-right-side-buttons {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

/* Buttons */

button {
    font-size: 0.6em;
    font-weight: 700;
    font-family:'Titillium Web', sans-serif;
    border-radius: 3px;
    border: none;
    box-shadow: 1px 1px 3px black;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    padding: 8px;
    width: 128px;
}

button > svg {
    margin-right: 6px;
}

button:hover {
    filter: brightness(0.75);
}

.shop-browser-item-buy-button {
    cursor: pointer;
    background-color: var(--light-red);
    color: white;
}

.shop-browser-item-price-button {
    background-color: rgb(70, 180, 0);
    color: white;
}

/* Media Versions */

@media only screen and (max-width: 1560px) {

    /* Browser */

    .shop-browser {
        width: 1300px;
    }

}

@media only screen and (max-width: 1350px) {

    /* Browser */

    .shop-browser {
        width: 800px;
    }

    .shop-browser-container {
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }

}

@media only screen and (max-width: 850px) {

    /* Browser */

    .shop-browser {
        width: 550px;
    }

    /* Left Side Of item */

    .shop-browser-item-left-side {
        width: 100px;
    }

    .shop-browser-item-left-side > img {
        width: 100px;
        height: 100px;
    }

    /* Right Side Of Item */

    .shop-browser-item-description {
        margin: 0px 0px 16px 0px;
        font-size: 0.6em;
    }

}

@media only screen and (max-width: 600px) {

    /* Browser */

    .shop-browser {
        width: 90%;
    }

    /* Item */

    .shop-browser-item {
        padding: 24px;
    }

    /* Left Side Of item */

    .shop-browser-item-left-side {
        display: none;
    }

    /* Right Side Of Item */

    .shop-browser-item-right-side {
        margin-left: 0px;
    }

    .shop-browser-item-right-side-buttons {
        flex-direction: row;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

}