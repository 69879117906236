.ranking > p {
    margin: 0.5em 0px;
    font-weight: 600;
    font-size: 2em;
    color: var(--light-red);
}

.tables-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    justify-content: center;
}

/* Tabele */

.table {
    font-size: 18px;
    width: 75%;
    justify-content: center;
}

.table:nth-child(odd) {margin-left: auto;}
.table:nth-child(even) {margin-right: auto;}

/* Wiersze */

.table-header {
    text-align: center;
    color: var(--light-red);
    width: 100%;
    font-size: 36px;
    margin: 16px 0px;
    font-weight: 700;
}

.table-row {
    padding: 6px 0;
    width: 100%;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

/* Komorki i ich zawartosc */

.table-cell {
    display: flex;
    width: 25%;
}

.table-cell:nth-child(1) {
    justify-content: start; 
    font-weight: 700;
    width: 100px;
}
.table-cell:nth-child(2) {
    justify-content: start; 
    font-weight: 700;
    width: 100px;
}
.table-cell:nth-child(3) {
    flex-direction: column;
}
.table-cell:nth-child(4) {
    justify-content: end;
}

.table-user-guild {
    border-radius: 8px;
    width: fit-content;
    padding: 4px;
    font-size: 0.6em;
    align-self: center;
    display: flex;
    font-weight: 700;
    min-width: 25px;
    justify-content: center;
}

.table-user-nick {
    display: flex;
    width: fit-content;
    align-self: center;
}



@media only screen and (max-width: 1200px) {

    .table {
        width: 90%;
    }

}

@media only screen and (max-width: 1024px) {

    .tables-container {
        grid-template-columns: 1fr;
    }

    .table {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

}

@media only screen and (max-width: 512px) {

    .table {
        width: 90%;
    }

    .table-cell:nth-child(1) {
        width: 40px;
    }
    .table-cell:nth-child(2) {
        width: 75px;
    }

}