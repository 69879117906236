.shop-item {
    text-align: left;
}

.shop-item-container {
    width: 1000px;
    background-color: rgba(255, 255, 255, 0.075);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    box-shadow: 0px 0px 16px rgb(20, 20, 20);
    padding: 32px 32px 24px 32px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.shop-item-header {
    font-size: 1.25em;
    font-weight: 600;
    margin: 32px 0px 32px 0px;
    color: var(--light-red);
}

.shop-selected-item {
    font-size: 0.8em;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    color: var(--light-red-darker);
    text-shadow: 1px 1px 15px var(--light-red);
}

.shop-item-input-header {
    margin: 0;
    font-size: 0.75em;
    font-weight: 200;
}

/* First Section */

.shop-item-data-container {
    flex-direction: row;
    display: flex;
    gap: 24px;
}

.shop-item-input-container > input {
    padding: 8px;
    width: 300px;
    border: none;
    background-color: #161616;
    outline: none;
    font-family: 'Titillium Web', sans-serif;
    color: white;
    border-radius: 3px;
    box-shadow: inset 0px 0px 2px black, 0px 0px 2px black;
    font-weight: 700;
    transition: 0.15s;
    font-size: 0.65em;
}

.shop-item-input-container > input:focus {
    box-shadow: inset 0px 0px 2px var(--light-red), 0px 0px 2px var(--light-red);
}

/* Second Section */

.shop-item-payment-container {
    flex-direction: row;
    display: flex;
    gap: 24px;
}

.shop-item-payment-container > button {
    width: 300px;
    height: 100px;
    padding: 8px;
    background: transparent;
    color: white;
    border: 1px solid rgb(100, 100, 100);
    box-shadow: none;
    cursor: pointer;
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
}

.shop-item-payment-container > button > svg {
    font-size: 1.5em;
}

.selected-payment {
    color: white !important;
    background-color: var(--light-red-darker) !important;
    transition: none;
}

.selected-payment:hover {
    filter: none !important;
}

/* Third Section */

.shop-item-summary {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.shop-rules-container {
    margin-top: 16px;
    display: flex;
    margin-right: 0;
    font-size: 0.75em;
    user-select: none;
}

label > a {
    color: var(--light-red);
}

label > a:hover {
    color: var(--light-red-darker);
}

/* Error Section */

.shop-summary-error {
    color: var(--light-red-darker);
    margin: 12px 0px 0px 0px;
    font-size: 0.75em;
    text-align: center;
}

@media only screen and (max-width: 1100px) {

    .shop-item {
        width: 90%; 
    }

    .shop-item-container {
        width: calc(100% - 64px);
    }

}

@media only screen and (max-width: 800px) {

    .shop-item {
       width: 95%; 
    }

    .shop-item-container {
        width: calc(100% - 32px);
        padding: 0px 16px 24px 16px;
    }

    /* First Section */

    .shop-item-data-container {
        flex-direction: column;
    }
    
    .shop-item-input-container > input {
        width: 300px;
    }

    /* Second Section */

    .shop-item-payment-container {
        gap: 8px;
    }
    
    .shop-item-payment-container > button {
        width: 250px;
    }

    /* Third Section */

    .shop-item-summary {
        gap: 16px;
        flex-direction: column;
    }

}

@media only screen and (max-width: 600px) {

    .shop-item-header {
        text-align: center;
    }

    .shop-browser-header {
        text-align: center;
    }

    .shop-item-input-container > input {
        width: calc(100% - 16px);
    }

    .shop-item-payment-container > button {
        width: 50%;
        font-size: 0.45em;
    }

}