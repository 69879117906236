.rules {
    margin: 0px 16px;
    text-align: left;
    padding: 32px 128px;
}

.rules-header {
    font-weight: 500;
    font-size: 1.25em;
    color: var(--light-red);
}

.rules-title {
    font-weight: 600;
    font-size: 1.25em;
    color: var(--light-red);
}

.rules-link {
    font-size: 0.65em;
    padding: 12px;
    border-radius: 3px;
    width: 240px;
    text-align: center;
    background-color: var(--light-red);
    font-weight: 700;
}

.rules-link:hover {
    background-color: var(--light-red-darker);
    transition: 0.2s;
}

.rules-text {
    font-weight: 300;
    font-size: 0.9em;
}

.rules-reference {
    color: var(--light-red-darker);
}

.rules-reference:hover {
    color: var(--light-red);
}

@media only screen and (max-width: 1100px) {

    .rules {
        padding: 8px 16px;
    }

    .rules-text {
        font-size: 0.65em;
    }

    .rules-link {
        margin-left: auto;
        margin-right: auto;
    }
}